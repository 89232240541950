import {
  PDFViewer,
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";

import { Meta } from "~/ui";
import { ModuleReflections, Routes } from "~/models";
import { useStoredReflections } from "~/utils";

import headerImg from "~/assets/images/reflection-header.png";
import footerImg from "~/assets/images/reflection-footer.png";

export default function Reflections(): JSX.Element | null {
  const { reflections } = useStoredReflections();

  if (!reflections.length) {
    return null;
  }

  const getModuleReflection = (moduleNumber: number) => {
    switch (moduleNumber) {
      case 1:
        return ModuleReflections.ModuleOne;
      case 2:
        return ModuleReflections.ModuleTwo;
      case 3:
        return ModuleReflections.ModuleThree;
      case 4:
        return ModuleReflections.ModuleFour;
    }
  };

  return (
    <PDFViewer style={{ width: "100vw", height: "100vh" }}>
      <Document>
        <Page
          size="A4"
          style={{ width: "100vw", height: "100vh", flex: "column" }}
        >
          <Image src={headerImg} />
          <View style={styles.mainContent}>
            {reflections.map(({ moduleNumber, reflection }) => (
              <View key={moduleNumber} style={styles.questionRow}>
                <Text style={styles.questionTitle}>
                  {getModuleReflection(moduleNumber)}
                </Text>
                {reflection ? (
                  <Text style={styles.text}>{reflection}</Text>
                ) : (
                  <Text style={[styles.text, styles.muted]}>
                    No reflection was supplied.
                  </Text>
                )}
              </View>
            ))}
          </View>
          <Image src={footerImg} style={styles.footerImg} />
        </Page>
      </Document>
    </PDFViewer>
  );
}

export const Head = () => {
  return <Meta title="Reflections" url={Routes.ReflectionsDownload} />;
};

const styles = StyleSheet.create({
  mainContent: {
    padding: "0 45px",
  },
  questionRow: {
    flexDirection: "column",
    justifyContent: "space-between",
    alignContent: "center",
    marginBottom: 30,
    borderLeft: "1px solid #DCDDDD",
    paddingLeft: 15,
    paddingVertical: 5,
  },
  questionTitle: {
    fontSize: 14,
    fontWeight: "bold",
    lineHeight: 1,
    marginBottom: 8,
  },
  text: {
    fontSize: 10,
    fontWeight: 500,
    lineHeight: 1.5,
  },
  muted: {
    color: "#6c757d",
  },
  footerImg: {
    position: "absolute",
    bottom: 30,
    right: 45,
    left: 45,
  },
});
